<template>
  <component :is="tag" :class="colorClass" class="m-0 font-weight-bold">
    <span>{{ text }}<template v-if="additionalText">: {{ additionalText }}</template>
    </span>
  </component>
</template>

<script>

/** Colors **/
const COLORS = ['silver', 'blue']

export default {
  name: 'StepperPagination',

  props: {
    color: {
      type: String,
      validator: value => {
        return COLORS.includes(value)
      },
      default: COLORS[0]
    },
    additionalText: {
      type: String,
      default: null
    },
    tag: {
      type: String,
      default: 'h5'
    },
    numberOfSteps: {
      type: Number,
      required: true
    },
    activeStep: {
      type: Number,
      required: true
    }
  },

  computed: {
    text () {
      return `${this.$t('step')} ${this.activeStep} ${this.$t('of')} ${this.numberOfSteps}`
    },

    colorClass () {
      return `stepper-pagination-text-${this.color}`
    }
  }
}
</script>

<style scoped lang="scss">
.stepper-pagination-text-silver {
 color: $color-text-gray;
}

.stepper-pagination-text-blue {
  color: $color-blue;
}
</style>
