const IdeaTypesEnum = {
  STARTUP_SMALL_BUSINESS: 1,
  PRODUCT_SERVICE: 2,
  PROJECT_EVENT: 3,
  JUST_WANNA_TRY: 4
}

const IdeaTypesNames = [
  {
    id: IdeaTypesEnum.STARTUP_SMALL_BUSINESS,
    name: 'startup'
  },
  {
    id: IdeaTypesEnum.PRODUCT_SERVICE,
    name: 'product'
  },
  {
    id: IdeaTypesEnum.PROJECT_EVENT,
    name: 'project'
  },
  {
    id: IdeaTypesEnum.JUST_WANNA_TRY,
    name: 'try'
  }
]

function getTypeNames (type) {
  return IdeaTypesNames.find(x => x.id === type).name
}

export { IdeaTypesEnum as default, getTypeNames }
