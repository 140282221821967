import IdeaTypesEnum from '@/constants/IdeaTypesEnum'
import i18n from '@/i18n'

const ideaTypes = [
  {
    title: i18n.t('pages.onBoarding.dialogIdeaType.ideaTypes.startupSmallBusiness.title'),
    subtitle: i18n.t('pages.onBoarding.dialogIdeaType.ideaTypes.startupSmallBusiness.subtitle'),
    img: require('@/assets/img/illustrations/select-idea/startup-small-business.png'),
    type: IdeaTypesEnum.STARTUP_SMALL_BUSINESS
  },
  {
    title: i18n.t('pages.onBoarding.dialogIdeaType.ideaTypes.productService.title'),
    subtitle: i18n.t('pages.onBoarding.dialogIdeaType.ideaTypes.productService.subtitle'),
    img: require('@/assets/img/illustrations/select-idea/product-service.png'),
    type: IdeaTypesEnum.PRODUCT_SERVICE
  },
  {
    title: i18n.t('pages.onBoarding.dialogIdeaType.ideaTypes.projectEvent.title'),
    subtitle: i18n.t('pages.onBoarding.dialogIdeaType.ideaTypes.projectEvent.subtitle'),
    img: require('@/assets/img/illustrations/select-idea/project-event.png'),
    type: IdeaTypesEnum.PROJECT_EVENT
  },
  {
    title: i18n.t('pages.onBoarding.dialogIdeaType.ideaTypes.justWannaTry.title'),
    subtitle: i18n.t('pages.onBoarding.dialogIdeaType.ideaTypes.justWannaTry.subtitle'),
    img: require('@/assets/img/illustrations/select-idea/just-wanna-try.png'),
    type: IdeaTypesEnum.JUST_WANNA_TRY
  }
]

const justWannaTryData = {
  ideaSettings: {
    name: 'Test Idea',
    country: 'US',
    planningFor: 3
  }
}

export {
  ideaTypes,
  justWannaTryData
}
